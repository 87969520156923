import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LandingHeader from "../../../components/src/LandingHeader.web";
import { LandingFooter } from "../../../components/src/LandingFooter.web";
import ImageDiv, { StyledContainer } from "./components/ImageDiv";
import TitleContainer from "./components/TitleContainer";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";
import { Accordion, AccordionDetails, AccordionSummary, styled, Typography } from "@material-ui/core";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const StyledAccordion = styled(Accordion)  ({
  marginBottom: '30px',
  border: '1px solid #DFDFDF',
  boxShadow: 'none',
  '&.Mui-expanded': {
    marginBottom: '30px',
  },  
});
const StyledAccordionSummary = styled(AccordionSummary) ({
  fontFamily : "Silika-Medium",
  fontWeight : 500,
  fontSize : "16px",
  lineHeight : "25px"                                 
});

const StyledAccordionDetails = styled(AccordionDetails)({
  fontFamily : "Silika-Light",
  fontWeight : 300,
  fontSize : "14px",
  lineHeight : "19.69px",
  '&.Mui-expanded': {
    marginBottom: '30px',
  }
});

// Customizable Area End

export default class SupportPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const faqData = this.state?.faqData;
    const supportFeatureData = this.state?.supportFeatureData[0]?.attributes||{};
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <LandingHeader navigation={this.props.navigation} />
        <StyledContainer>
          <TitleContainer
            title={supportFeatureData.title}
            description={supportFeatureData.description}
          />
          {supportFeatureData.help_features?.data.map((item, index) => (
            <ImageDiv
              title={item.attributes.title}
              description={item.attributes.description}
              image={item.attributes.image}
              direction={index % 2 === 0 ? "right" : "left"}
              handleNavigation={this.handleNavigation}
              fullView={true}
            />
          ))}
          <div className="questionDiv">
            <div className="questionBox">
              <div className="questionBoxTitle">
                <Typography className="questionTitle">
                  {faqData[0]?.attributes.question}
                </Typography>
                <Typography className="questionDescription">
                {faqData[0]?.attributes.answer}
                </Typography>
              </div>
              <div>
                {faqData.slice(1).map((faq, index)=>(
                  <StyledAccordion key={index} >
                    <StyledAccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      {faq.attributes.question}
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                    {faq.attributes.answer}
                    </StyledAccordionDetails>
                </StyledAccordion>
                ))}
              </div>
            </div>
          </div>
        </StyledContainer>
        <LandingFooter navigation={this.props.navigation} />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End
