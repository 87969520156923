Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getUserGalleryEndPoint='bx_block_posts/posts/gallery'
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
exports.getProfileEndPoint = "bx_block_profile/profiles"
exports.getEducationEndPoint = "bx_block_profile_bio/educations?profile_id="
exports.getExperience = "bx_block_profile_bio/careers?profile_id="
exports.createExperienceEndPoint = "bx_block_profile_bio/careers"
exports.editSingleExperience = "bx_block_profile_bio/careers/"
exports.createEddducation = "bx_block_profile_bio/educations?profile_id="
exports.EditSingleEducation = "bx_block_profile_bio/educations/"
exports.deleteCollabPosts = "bx_block_posts/posts/remove_collaborator"
exports.deletePostAPiMethod = "DELETE";
exports.putDragDropResultApiEndPoint = "bx_block_posts/posts/update_gallery"
exports.ENDPOINTS = {
  DELETE_EXPERIENCE: "bx_block_profile_bio/careers",
  DELETE_EDUCATION: "bx_block_profile_bio/educations"
}
// Customizable Area End
